import { render, staticRenderFns } from "./my-dialog.vue?vue&type=template&id=07b00763&scoped=true"
import script from "./my-dialog.vue?vue&type=script&lang=js"
export * from "./my-dialog.vue?vue&type=script&lang=js"
import style0 from "./my-dialog.vue?vue&type=style&index=0&id=07b00763&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b00763",
  null
  
)

export default component.exports