// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my-form-item-group{width:100%}.my-form-item-group .form-item-group_title{background-color:#fde8ea;border-bottom:1px solid #e8e8e8;border-right:1px solid #e8e8e8;color:#606266;font-weight:700;line-height:32px;padding:0 1em}.my-form-item-group .form-item-group_title:before{content:\"◆\";margin-right:.5em}.my-form-item-group .form-item-group_body{display:flex;flex-wrap:wrap;position:relative;width:100%}.my-form-item-group .form-item-group_body:after{border-right:1px solid #e8e8e8;content:\"\";display:block;height:100%;position:absolute;right:0;top:0;width:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#e91b2b",
	"primaryColor9": "#eb3240",
	"primaryColor8": "#ed4955",
	"primaryColor7": "#f05f6b",
	"primaryColor6": "#f27680",
	"primaryColor5": "#f48d95",
	"primaryColor4": "#f6a4aa",
	"primaryColor3": "#f8bbbf",
	"primaryColor2": "#fbd1d5",
	"primaryColor1": "#fde8ea",
	"successColor": "#52c41a",
	"successColor9": "#63ca31",
	"successColor8": "#75d048",
	"warningColor": "#faad14",
	"warningColor9": "#fbb52c",
	"warningColor8": "#fbbd43",
	"dangerColor": "#ef4040",
	"dangerColor9": "#f15353",
	"dangerColor8": "#f26666",
	"infoColor": "#909399",
	"infoColor9": "#9b9ea3",
	"infoColor8": "#a6a9ad",
	"navBgColor": "#304156",
	"navTextColor": "#bfcbd9",
	"navTextActiveColor": "#ffd04b"
};
module.exports = ___CSS_LOADER_EXPORT___;
